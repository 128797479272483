<template>
  <b-tabs fill justified>
    <b-tab :title="$t('system.netdb-' + v)" v-for="v in ['release', 'oldrelease', 'deprecated']" v-bind:key="v">
      <!-- eslint-disable vue/no-v-text-v-html-on-component !-->
      <b-alert class="m-5" show variant="info" v-html="$t('views.swagger.netdb_auth_explain')"></b-alert>
      <div :id="'swagger-ui-netdb-' + v">
      </div>
    </b-tab>
    <b-tab :title="$t('system.middleware')">
      <div id="swagger-ui-middleware">
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import WapiVersion from '@/api-services.gen/wapi.version'

export default {
  name: 'SwaggerUI',
  async mounted() {
    var self = this
    const api_versions_result = await WapiVersion.list(this.$store.state, {})
    console.log('Versions', api_versions_result)
    this.api_versions = api_versions_result.data[0]
    let has_release = false
    for (const vers of this.api_versions) {
      if (vers.semantic === 'release') {
        has_release = true
      }
    }
    for (const vers of this.api_versions) {
      if (vers.semantic === 'alpha' && has_release) {
        continue
      }
      if (vers.semantic === 'alpha' && !has_release) {
        vers.semantic = 'release'
      }
      SwaggerUI({
        tagsSorter: 'alpha',
        apisSorter: 'alpha',
        url: '/api_' + vers.major + '_' + vers.minor + '.yml',
        docExpansion: 'none',
        dom_id: '#swagger-ui-netdb-' + vers.semantic,
        deepLinking: false,
        filter: true,
        presets: [
          SwaggerUI.presets.apis
        ],
        plugins: [
          SwaggerUI.plugins.DownloadUrl
        ],
        requestInterceptor: function (req) {
          if (self.$store.state.user) {
            req.headers.Authorization = 'Bearer ' + self.$store.state.token.token
            window.console.log('Authorized from Session')
          }
          return req
        }
      })
    }
    SwaggerUI({
      tagsSorter: 'alpha',
      apisSorter: 'alpha',
      url: '/api/openapi.json',
      docExpansion: 'none',
      dom_id: '#swagger-ui-middleware',
      deepLinking: false,
      filter: true,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        SwaggerUI.plugins.DownloadUrl
      ],
      requestInterceptor: function (req) {
        if (self.$store.state.token) {
          req.headers.Authorization = 'Bearer ' + self.$store.state.token.token
          window.console.log('Authorized from Session')
        }
        return req
      }
    })
  }
}
</script>

<style scoped>

</style>
